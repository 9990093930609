var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-card', {
    attrs: {
      "no-body": ""
    }
  }, [_c('b-card-body', {
    staticClass: "pb-1"
  }, [_c('b-card-title', [_vm._v("Display headings")]), _c('b-card-text', [_vm._v(" Traditional heading elements are designed to work best in the meat of your page content. When you need a heading to stand out, consider using a "), _c('strong', [_vm._v("display heading")]), _vm._v(" — a larger, slightly more opinionated heading style. ")])], 1), _c('b-table-simple', {
    attrs: {
      "borderless": ""
    }
  }, [_c('b-tbody', [_c('b-tr', [_c('b-td', [_c('h1', {
    staticClass: "display-1"
  }, [_vm._v(" Display 1 ")])])], 1), _c('b-tr', [_c('b-td', [_c('h2', {
    staticClass: "display-2"
  }, [_vm._v(" Display 2 ")])])], 1), _c('b-tr', [_c('b-td', [_c('h3', {
    staticClass: "display-3"
  }, [_vm._v(" Display 3 ")])])], 1), _c('b-tr', [_c('b-td', [_c('h4', {
    staticClass: "display-4"
  }, [_vm._v(" Display 4 ")])])], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }
var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-row', {
    staticClass: "match-height"
  }, [_c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('b-card', {
    attrs: {
      "no-body": ""
    }
  }, [_c('b-card-header', [_c('b-card-title', [_vm._v("HTML headings "), _c('small', {
    staticClass: "text-muted"
  }, [_vm._v("Default")])])], 1), _c('b-card-body', {
    staticClass: "pb-1"
  }, [_c('b-card-text', [_vm._v(" All HTML headings, "), _c('code', [_vm._v("<h1>")]), _vm._v(" through "), _c('code', [_vm._v("<h6>")]), _vm._v(", are available. "), _c('code', [_vm._v(".h1")]), _vm._v(" through "), _c('code', [_vm._v(".h6")]), _vm._v(" classes are also available, for when you want to match the font styling of a heading. ")])], 1), _c('b-table-simple', {
    attrs: {
      "borderless": ""
    }
  }, [_c('b-thead', {
    attrs: {
      "head-variant": "danger"
    }
  }, [_c('b-tr', [_c('b-th', [_vm._v("PREVIEW")]), _c('b-th', {
    staticClass: "text-right"
  }, [_vm._v(" FONT SIZE ")])], 1)], 1), _c('b-tbody', [_c('b-tr', [_c('b-td', [_c('h1', [_vm._v("H1 Heading")])]), _c('b-td', {
    staticClass: "text-right"
  }, [_vm._v(" 2rem ")])], 1), _c('b-tr', [_c('b-td', [_c('h2', [_vm._v("H2 Heading")])]), _c('b-td', {
    staticClass: "text-right"
  }, [_vm._v(" 1.714rem ")])], 1), _c('b-tr', [_c('b-td', [_c('h3', [_vm._v("H3 Heading")])]), _c('b-td', {
    staticClass: "text-right"
  }, [_vm._v(" 1.5rem ")])], 1), _c('b-tr', [_c('b-td', [_c('h4', [_vm._v("H4 Heading")])]), _c('b-td', {
    staticClass: "text-right"
  }, [_vm._v(" 1.286rem ")])], 1), _c('b-tr', [_c('b-td', [_c('h5', [_vm._v("H5 Heading")])]), _c('b-td', {
    staticClass: "text-right"
  }, [_vm._v(" 1.07rem ")])], 1), _c('b-tr', [_c('b-td', [_c('h6', [_vm._v("H6 Heading")])]), _c('b-td', {
    staticClass: "text-right"
  }, [_vm._v(" 1rem ")])], 1)], 1)], 1)], 1)], 1), _c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('b-card', {
    attrs: {
      "no-body": ""
    }
  }, [_c('b-card-header', [_c('b-card-title', [_vm._v("Light / Bold Headings")])], 1), _c('b-card-body', {
    staticClass: "pb-1"
  }, [_c('b-card-text', [_vm._v(" All HTML headings are available with light and bold font-weight. Use "), _c('code', [_vm._v(".font-weight-normal")]), _vm._v(" for light heading and "), _c('code', [_vm._v(".font-weight-bolder")]), _vm._v(" for bold headings along with heading tags or classes. ")])], 1), _c('b-table-simple', {
    attrs: {
      "borderless": ""
    }
  }, [_c('b-thead', {
    attrs: {
      "head-variant": "danger"
    }
  }, [_c('b-tr', [_c('b-th', [_vm._v("LIGHT HEADINGS")]), _c('b-th', {
    staticClass: "text-right"
  }, [_vm._v(" BOLD HEADINGS ")])], 1)], 1), _c('b-tbody', [_c('b-tr', [_c('b-td', [_c('h1', {
    staticClass: "font-weight-normal"
  }, [_vm._v(" Heading 1 ")])]), _c('b-td', {
    staticClass: "text-right"
  }, [_c('h1', {
    staticClass: "font-weight-bolder"
  }, [_vm._v(" Heading 1 ")])])], 1), _c('b-tr', [_c('b-td', [_c('h2', {
    staticClass: "font-weight-normal"
  }, [_vm._v(" Heading 2 ")])]), _c('b-td', {
    staticClass: "text-right"
  }, [_c('h2', {
    staticClass: "font-weight-bolder"
  }, [_vm._v(" Heading 2 ")])])], 1), _c('b-tr', [_c('b-td', [_c('h3', {
    staticClass: "font-weight-normal"
  }, [_vm._v(" Heading 3 ")])]), _c('b-td', {
    staticClass: "text-right"
  }, [_c('h3', {
    staticClass: "font-weight-bolder"
  }, [_vm._v(" Heading 3 ")])])], 1), _c('b-tr', [_c('b-td', [_c('h4', {
    staticClass: "font-weight-normal"
  }, [_vm._v(" Heading 4 ")])]), _c('b-td', {
    staticClass: "text-right"
  }, [_c('h4', {
    staticClass: "font-weight-bolder"
  }, [_vm._v(" Heading 4 ")])])], 1), _c('b-tr', [_c('b-td', [_c('h5', {
    staticClass: "font-weight-normal"
  }, [_vm._v(" Heading 5 ")])]), _c('b-td', {
    staticClass: "text-right"
  }, [_c('h5', {
    staticClass: "font-weight-bolder"
  }, [_vm._v(" Heading 5 ")])])], 1), _c('b-tr', [_c('b-td', [_c('h6', {
    staticClass: "font-weight-normal"
  }, [_vm._v(" Heading 6 ")])]), _c('b-td', {
    staticClass: "text-right"
  }, [_c('h6', {
    staticClass: "font-weight-bolder"
  }, [_vm._v(" Heading 6 ")])])], 1)], 1)], 1)], 1)], 1), _c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('b-card', {
    attrs: {
      "no-body": ""
    }
  }, [_c('b-card-header', [_c('b-card-title', [_vm._v("Customizing headings "), _c('small', {
    staticClass: "text-muted"
  }, [_vm._v("Default")])])], 1), _c('b-card-body', {
    staticClass: "pb-1"
  }, [_c('b-card-text', [_vm._v("Use the included utility classes to recreate the small secondary heading text.")])], 1), _c('b-table-simple', {
    attrs: {
      "borderless": ""
    }
  }, [_c('b-tbody', [_c('b-tr', [_c('b-td', [_c('h1', [_vm._v("Display heading "), _c('small', {
    staticClass: "text-muted"
  }, [_vm._v("Secondary text")])])])], 1), _c('b-tr', [_c('b-td', [_c('h2', [_vm._v("Display heading "), _c('small', {
    staticClass: "text-muted"
  }, [_vm._v("Secondary text")])])])], 1), _c('b-tr', [_c('b-td', [_c('h3', [_vm._v("Display heading "), _c('small', {
    staticClass: "text-muted"
  }, [_vm._v("Secondary text")])])])], 1), _c('b-tr', [_c('b-td', [_c('h4', [_vm._v("Display heading "), _c('small', {
    staticClass: "text-muted"
  }, [_vm._v("Secondary text")])])])], 1), _c('b-tr', [_c('b-td', [_c('h5', [_vm._v("Display heading "), _c('small', {
    staticClass: "text-muted"
  }, [_vm._v("Secondary text")])])])], 1), _c('b-tr', [_c('b-td', [_c('h6', [_vm._v("Display heading "), _c('small', {
    staticClass: "text-muted"
  }, [_vm._v("Secondary text")])])])], 1)], 1)], 1)], 1)], 1), _c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('b-card', {
    attrs: {
      "no-body": ""
    }
  }, [_c('b-card-header', [_c('b-card-title', [_vm._v("Heading colors")])], 1), _c('b-card-body', {
    staticClass: "pb-1"
  }, [_c('b-card-text', [_vm._v(" Heading elements are also changed with different color options. Use "), _c('code', [_vm._v(".text-{colorName}")]), _vm._v(" class with heading elements. ")])], 1), _c('b-table-simple', {
    attrs: {
      "borderless": ""
    }
  }, [_c('b-tbody', [_c('b-tr', [_c('b-td', [_c('h1', {
    staticClass: "text-primary"
  }, [_vm._v(" Display heading ")])])], 1), _c('b-tr', [_c('b-td', [_c('h2', {
    staticClass: "text-success"
  }, [_vm._v(" Display heading ")])])], 1), _c('b-tr', [_c('b-td', [_c('h3', {
    staticClass: "text-danger"
  }, [_vm._v(" Display heading ")])])], 1), _c('b-tr', [_c('b-td', [_c('h4', {
    staticClass: "text-warning"
  }, [_vm._v(" Display heading ")])])], 1), _c('b-tr', [_c('b-td', [_c('h5', {
    staticClass: "text-info"
  }, [_vm._v(" Display heading ")])])], 1), _c('b-tr', [_c('b-td', [_c('h6', [_vm._v("Display heading")])])], 1)], 1)], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }
var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('section', {
    attrs: {
      "id": "blockquotes"
    }
  }, [_c('b-row', {
    staticClass: "match-height"
  }, [_c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('div', {
    staticClass: "group-area mt-1"
  }, [_c('h4', [_vm._v("Blockquotes")]), _c('p', [_vm._v(" For quoting blocks of content from another source within your document. Wrap "), _c('code', {
    staticClass: "highlighter-rouge"
  }, [_vm._v("<blockquote class=\"blockquote\">")]), _vm._v(" around any "), _c('abbr', {
    attrs: {
      "title": "HyperText Markup Language"
    }
  }, [_vm._v("HTML")]), _vm._v(" as the quote. ")]), _c('hr')])]), _c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('b-card', {
    attrs: {
      "no-body": ""
    }
  }, [_c('b-card-header', [_c('b-card-title', [_vm._v(" Blockquotes "), _c('small', {
    staticClass: "text-muted"
  }, [_vm._v("Default")])])], 1), _c('b-card-body', [_c('b-card-text', [_vm._v(" Left aligned basic blockquotes. Use text utilities classes like "), _c('code', [_vm._v(".text-center / .text-right")]), _vm._v(" as needed to change the alignment of your blockquote. ")]), _c('blockquote', {
    staticClass: "blockquote"
  }, [_c('p', {
    staticClass: "mb-0"
  }, [_vm._v(" Design is not just what it looks like and feels like. Design is how it works. ")])])], 1)], 1)], 1), _c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('b-card', {
    attrs: {
      "no-body": ""
    }
  }, [_c('b-card-header', [_c('b-card-title', [_vm._v(" Naming a source "), _c('small', {
    staticClass: "text-muted"
  }, [_vm._v("Default")])])], 1), _c('b-card-body', [_c('b-card-text', [_vm._v(" Add a "), _c('code', {
    staticClass: "highlighter-rouge"
  }, [_vm._v("<footer class=\"blockquote-footer\">")]), _vm._v(" for identifying the source. Wrap the name of the source work in "), _c('code', {
    staticClass: "highlighter-rouge"
  }, [_vm._v("<cite>")]), _vm._v(". ")]), _c('blockquote', {
    staticClass: "blockquote"
  }, [_c('p', {
    staticClass: "mb-0"
  }, [_vm._v(" Being the richest man in the cemetery doesn't matter to me. Going to bed at night saying we've done something wonderful, that's what matters to me. ")]), _c('footer', {
    staticClass: "blockquote-footer"
  }, [_vm._v(" Steve Jobs "), _c('cite', {
    attrs: {
      "title": "Source Title"
    }
  }, [_vm._v("Entrepreneur")])])])], 1)], 1)], 1), _c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('b-card', [_c('b-card-title', [_vm._v(" Blockquotes styling "), _c('small', {
    staticClass: "text-muted"
  }, [_vm._v("Default")])]), _c('b-card-text', [_vm._v(" Add a "), _c('code', {
    staticClass: "highlighter-rouge"
  }, [_vm._v(".border-{left/right}-{color} .border-{left/right}-3")]), _vm._v(" helper classes, where color can be any color from Vuexy Admin color palette. ")]), _c('blockquote', {
    staticClass: "blockquote pl-1 border-left-primary border-left-3"
  }, [_c('p', {
    staticClass: "mb-0"
  }, [_vm._v(" Sometimes when you innovate, you make mistakes. It is best to admit them quickly, and get on with improving your other innovations. ")]), _c('footer', {
    staticClass: "blockquote-footer"
  }, [_vm._v(" Steve Jobs "), _c('cite', {
    attrs: {
      "title": "Source Title"
    }
  }, [_vm._v("Entrepreneur")])])]), _c('blockquote', {
    staticClass: "blockquote pr-1 mt-2 text-right border-right-primary border-right-3"
  }, [_c('p', {
    staticClass: "mb-0"
  }, [_vm._v(" Sometimes when you innovate, you make mistakes. It is best to admit them quickly, and get on with improving your other innovations. ")]), _c('footer', {
    staticClass: "blockquote-footer"
  }, [_vm._v(" Steve Jobs "), _c('cite', {
    attrs: {
      "title": "Source Title"
    }
  }, [_vm._v("Entrepreneur")])])])], 1)], 1), _c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('b-card', [_c('b-card-title', [_vm._v("Blockquotes with avatar Default")]), _c('b-card-text', [_vm._v(" Blockquotes with avatar. it use Media Object. You can customize image type, border alignment & style. ")]), _c('blockquote', {
    staticClass: "blockquote pl-1 border-left-primary border-left-3"
  }, [_c('b-media', {
    attrs: {
      "no-body": ""
    }
  }, [_c('b-media-aside', {
    staticClass: "mr-1"
  }, [_c('b-img', {
    attrs: {
      "src": require('@/assets/images/portrait/small/avatar-s-5.jpg'),
      "alt": "Generic placeholder image",
      "height": "64",
      "width": "64"
    }
  })], 1), _c('b-media-body', [_vm._v(" Sometimes life is going to hit you in the head with a brick. Don't lose faith. ")])], 1), _c('footer', {
    staticClass: "blockquote-footer text-right"
  }, [_vm._v(" Steve Jobs "), _c('cite', {
    attrs: {
      "title": "Source Title"
    }
  }, [_vm._v("Entrepreneur")])])], 1), _c('b-card-text', {
    staticClass: "mt-2"
  }, [_vm._v(" Blockquotes with avatar rounded image example ")]), _c('blockquote', {
    staticClass: "blockquote mt-1"
  }, [_c('b-media', {
    attrs: {
      "no-body": ""
    }
  }, [_c('b-media-aside', {
    staticClass: "mr-1"
  }, [_c('b-img', {
    attrs: {
      "rounded": "circle",
      "src": require('@/assets/images/portrait/small/avatar-s-3.jpg'),
      "alt": "Generic placeholder image",
      "height": "64",
      "width": "64"
    }
  })], 1), _c('b-media-body', [_vm._v(" Sometimes life is going to hit you in the head with a brick. Don't lose faith. ")])], 1), _c('footer', {
    staticClass: "blockquote-footer text-right"
  }, [_vm._v(" Steve Jobs "), _c('cite', {
    attrs: {
      "title": "Source Title"
    }
  }, [_vm._v("Entrepreneur")])])], 1)], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }